<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/user">系统用户</BreadcrumbItem>
        <BreadcrumbItem>重置密码</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Form ref="dataForm" :model="formData" :rules="rules" :label-width="88" >
        <FormItem label="用户名称：" style="text-align:left;">
          <span>{{formData.username}}</span>
        </FormItem>
        <FormItem label="真实姓名：" style="text-align:left;">
          <span>{{formData.realName}}</span>
        </FormItem>
        <FormItem label="密码：" prop="password">
          <Input type="password" v-model="formData.password" />
        </FormItem>
        <FormItem label="重复密码" prop="passwdCheck">
          <Input type="password" v-model="formData.passwdCheck" />
        </FormItem>
        <FormItem>
          <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
        </FormItem>  
      </Form>  
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.formData.passwdCheck !== '') {
          // 对第二个密码框单独验证
          this.$refs.dataForm.validateField('passwdCheck')
        }
        callback()
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入的密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userId: null,
      formData: {
        username: '',
        realName: '',
        password: '',
        passwdCheck: '',
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        passwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.userId = this.$route.params.id
    this.getData()
  },
  methods: {
    getData() {
      request.get(`/api/sys/user/${this.userId}`)
      .then((data) => {
        const { username, realName } = data
        this.formData = { username, realName }
      })
    },
    submit() {
      const params = this.formData
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          request.put(`/api/sys/user/reset_pwd/${this.userId}`, params)
          .then((res) => {
            this.$Message.success('重置成功!')
            this.$router.replace('/sys_manage/user')
          })
        }
      })
    },
  },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    justify-content: center;
    color: #0077EE;
  }
  form {
    width: 450px;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
